@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Raleway:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700&display=swap);
@charset "utf-8";
/* CSS Document */

/*@font-face {
  font-family: 'Gotham Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Gotham Bold'), url('assets/GothamBold.woff') format('woff');
  }*/

  h1, h2, h3, h4 {
    font-family: 'Raleway', sans-serif;
  }
  
  h1 {
    font-size: 64px;
  }
  
  h2 {
    font-size: 42px;
    font-weight: 500;
  }
  
  h4 {
    font-size: 15px;
    font-weight: 500;
  }

@font-face {
  font-family: "Helvetica-Condensed";
  src: url(/static/media/Helvetica-Condensed.203373a8.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HelveticaNeueLTStd-Cn";
  src: url(/static/media/HelveticaNeueLTStd-Cn.75303684.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}
/*=================== // fonts // ====================*/

:root {
  --primary-color: #1acfd8;
  --secondary-color: #476D72;
  --grey: #d2d2d2;
  overflow-y: scroll;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  font-family: "Inter";
  background-image: url(/static/media/bg.3494e660.png);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  padding: 0;
  margin: 0;
  font-family: 'Raleway', sans-serif;
}
.img-responsive {
  display: block;
  height: auto;
}
.clearfix {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}
img {
  max-width: 100%;
  height: 0 auto;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0s ease;
  -webkit-transition: all 0s ease;
  opacity: 0;
  transform: translateY(200px);
}

.loaded {
  opacity: 1;
  transform: translateY(0);
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

ol,
dl,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-word;
}

a {
  color: #868686;
}

a:focus {
  outline: none;
}
:focus {
  outline: none;
}

/* Progress bar */
progress[value] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 200px;
  height: 10px;
}

progress[value]::-webkit-progress-bar {
  background-color: rgb(223, 223, 223);
  border-radius: 20px;
}

progress[value]::-webkit-progress-value {
  border-radius: 20px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
  animation: animate-stripes 5s linear infinite;
  background: #51777c;
}

progress[value]::-moz-progress-bar {
  background: -moz-linear-gradient(135deg, #476D72 0%, #476D72 100%);
}

@keyframes animate-stripes {
  100% {
    background-position: -100px 0px;
  }
}

input[type="range"] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 36px;
  width: 36px;
  border-radius: 3px;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.15);
  background: white;
  cursor: pointer;
  margin-top: -16px;
}

/* All the same stuff for Firefox */
input[type="range"]::-moz-range-thumb {
  height: 36px;
  width: 36px;
  border-radius: 3px;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.15);
  background: white;
  cursor: pointer;
  margin-top: -16px;
}

/* All the same stuff for IE */
input[type="range"]::-ms-thumb {
  height: 36px;
  width: 36px;
  border-radius: 3px;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.15);
  background: white;
  cursor: pointer;
  margin-top: -16px;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #b5b5b5;
  border-radius: 5px;
  border: 0.2px solid #b5b5b5;
}

input[type="range"]:nth-child(2)::-webkit-slider-runnable-track {
  background: none;
  border: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #b5b5b5;
}

input[type="range"]:nth-child(2):focus::-webkit-slider-runnable-track {
  background: none;
  border: none;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  border-radius: 1.3px;
  border: 0.2px solid #b5b5b5;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #b5b5b5;
  border: 0.2px solid #b5b5b5;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px gray, 0px 0px 1px gray;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #b5b5b5;
}
input[type="range"]::-ms-fill-upper {
  background: black;
  border: 0.2px solid black;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px gray, 0px 0px 1px gray;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #e20074;
}

textarea::-webkit-input-placeholder {
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
}

/*=================== // body // ====================*/
.header {
  margin-bottom: 0.7rem;
  font-weight: 700;
  text-align: center;
  font-size: 30px;
  max-width: 30ch;
  line-height: 140%;
  color: #1e1e1e;
}
.header2 {
  margin-bottom: 40px;
  font-weight: 600;
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  color: #1e1e1e;
}
.form-container {
  width: 52%;
  justify-content: space-around;
}
.form-container-scnd {
  width: 60%;
  margin: 0 auto;
}
.card-holder {
  flex-direction: column;
  align-items: center;
  margin: 3rem auto 0;
  display: table;
}
.card {
  padding: 48px;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  background: white;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.08);
  margin: 0 40px 15px;
  text-align: center;
  transition: 100ms all ease-out;
}

.selected {
  background-color: #51777c !important;
  color: white !important;
}

.card a {
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  color: #1e1e1e;
  font-weight: 400;
  text-decoration: none;
}
.card:hover a {
  color: #000;
  border-color: #1acfd8;
}
.card i {
  font-size: 50px;
}
.can-hover .card:hover {
  border-color: #e20074;
  color: #1acfd8;
}
.can-hover .card:hover i {
  color: #fff;
}
.card-box {
  width: 49%;
  float: left;
}
.card-box h4 {
  text-align: center;
  font-family: "Inter";
  font-size: 18px;
  color: #1e1e1e;
}
.btm-pnl {
  width: 80%;
  float: right;
  margin-top: 10%;
}
.rgt-aline1 {
  float: right;
  width: 45%;
}

.progressBar {
  margin-bottom: 26px;
  position: absolute;
}
.progress-bar {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
}
.progress-title {
  left: 50%;
  transform: translateX(-50%);
  width: 45px;
  text-align: center;

  position: absolute;
  color: #545454;
  font-size: 14px;
  font-weight: 400;
  bottom: 35px;
}
.progressBar h4 {
  font-size: 15px;
  text-transform: none;
  margin-bottom: 7px;
  margin-bottom: 0.33em;
  color: #2e1437;
}
.progressBarcontainer {
  width: 100%;
  height: 10px;
  background: #d8d8d8 !important;
  border-radius: 0.3em;
}
.progressBarValue {
  height: 10px;
  float: left;
  border-radius: 0.3em;
}

.speech-bubble {
  font-size: 0.75em;
  line-height: 2em;
  position: absolute;
  top: -5px;
  text-align: center;
  min-width: 3em;
  border-radius: 0.3em;
  color: white;
  display: none;
}

.speech-bubble:after {
  border: 0.5em solid transparent;
  content: "";
  margin-left: -0.5em;
  position: absolute;
  bottom: -50%;
  left: 50%;
}

.bar-1 {
  left: calc(88.5% - 1.5em);
}

.bar-2 {
  left: calc(20.5% - 1.5em);
}

.bar-3 {
  left: calc(35.5% - 1.5em);
}

.bar-4 {
  left: calc(48.5% - 1.5em);
}

.bar-5 {
  left: calc(58.5% - 1.5em);
}

.bar-1,
.bar-2,
.bar-3,
.bar-4,
.bar-5 {
  background: #a487b2;
}

.inner-container {
  width: 100%;
}
.inpt1,
.inpt2,
.inpt3 {
  width: 50%;
  background-color: white;
  font-family: "Inter";
  font-size: 14px;
  border: none;
  color: #141414;
  padding: 18px 24px;
  margin-bottom: 1rem;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.inpt1::-webkit-input-placeholder {
  color: #868686;
}

.inpt1:-ms-input-placeholder {
  color: #868686;
}

.inpt1::placeholder {
  color: #868686;
}

.inpt3 {
  width: 100%;
  float: left;
  min-height: 100px;
  padding: 10px;
}
.inner-container a {
  color: #1d1d1d;
  text-decoration: none !important;
}
.btn1 {
  background-color: black;
  padding: 16px 60px;
  color: white !important;
  border: none;
  border-radius: 9999px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  align-content: center;
  transition: 200ms ease-out all;
}
.btn1:hover {
  transform: translateY(-2px);
}

.buttons-row {
  margin: 20px auto 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: left;
}

.form-submit {
  width: 40%;
}

/*input[type='range'] {*/
/*  overflow: hidden;*/
/*  -webkit-appearance: none;*/
/*  background-color: grey;*/
/*}*/

/*input[type='range']::-webkit-slider-runnable-track {*/
/*  height: 8px;*/
/*  -webkit-appearance: none;*/
/*  color: #13bba4;*/
/*  margin-top: -1px;*/
/*}*/

/*input[type='range']::-webkit-slider-thumb {*/
/*  width: 10px;*/
/*  -webkit-appearance: none;*/
/*  height: 10px;*/
/*  cursor: ew-resize;*/
/*  background: #434343;*/
/*  box-shadow: -80px 0 0 80px #43e5f7;*/
/*}*/

/*input[type="range"]::-moz-range-progress {*/
/*  background-color: #43e5f7;*/
/*}*/
/*input[type="range"]::-moz-range-track {*/
/*  background-color: #9a905d;*/
/*}*/
/*!* IE*!*/
/*input[type="range"]::-ms-fill-lower {*/
/*  background-color: #43e5f7;*/
/*}*/
/*input[type="range"]::-ms-fill-upper {*/
/*  background-color: #9a905d;*/
/*}*/

.answers .buttons-row {
  margin: 50px 0% 0;
}

.answers .buttons-row .button-width {
  width: 25%;
}

.button-width {
  width: 0%;
}

.slct-bx1 {
  width: 32%;
  float: left;
  margin-right: 16px;
}
.inpt-wdth {
  width: 90%;
}
.rgt-mrgn2 {
  margin-right: 0;
}

.more-info {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 16px;
  border: 1px solid #5e5e5e;
  border-radius: 100px;
  z-index: 1000;
  cursor: pointer;
  line-height: 6px;
  padding: 2px 0 0 0;
}
.check-box {
  width: 100%;
  background-color: white;
  font-family: "Inter";
  font-size: 14px;
  border: none;
  transition: 100ms all ease-out;
  color: #141414;
  cursor: pointer;
  padding: 14px 24px;
  margin-bottom: 1rem;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.08);
}

.check-box h3 {
  font-weight: 400;
}

/* tooltip */
.select-box {
  height: 44px;
  width: 90%;
  cursor: pointer;
  padding: 12px 22px;
  margin-bottom: 1rem;
  border-radius: 12px;
  border: none;
  display: flex;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.08);
  justify-content: space-between;
  align-items: center;
  position: relative;
}

span {
  color: #5e5e5e;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
}

span:after {
  font-family: 'Raleway', sans-serif;
  text-align: left;
  white-space: normal;
}

span:focus {
  outline: none;
}

.wrap {
  background: #ecf0f1;
  color: #607d8b;
  height: 100%;
  overflow: auto;
  padding: 1em 2.5em;
  text-align: center;
  width: 100%;
}

pre {
  background: #fff;
  display: inline-block;
  font-size: 0.55em;
  margin-top: 2em;
  padding: 1em;
}

@media (min-width: 360px) {
  pre {
    font-size: 0.7em;
  }
}

@media (min-width: 500px) {
  pre {
    font-size: 1em;
  }
}

/*== start of code for tooltips ==*/
.tool {
  cursor: help;
  position: relative;
}

/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
  left: 50%;
  opacity: 0;
  position: absolute;
  z-index: -1000;
}

.more-info:hover .tool::before,
.more-info:focus .tool::before,
.more-info:hover .tool::after,
.more-info:focus .tool::after {
  opacity: 1;
  transform: scale(1) translateY(0);
  z-index: 100;
}
.more-info:hover .tool::before,
.more-info:focus .tool::before {
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
}

/*== speech bubble ==*/
.tool::after {
  background: #3e474f;
  border-radius: 0.25em;
  top: 10%;
  font-size: 14px;
  color: #fff;
  content: attr(data-tip);
  padding: 1em;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
  transform: scale(0.6) translateY(50%);
  width: 17.5em;
}

.more-info:hover .tool::after,
.more-info:focus .tool::after {
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
}

@media (max-width: 760px) {
  .tool::after {
    font-size: 0.75em;
    margin-left: -5em;
    width: 10em;
  }
}

.question-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem auto 0;
  width: 100%;
}
.question-box-lft {
  width: 50%;
}
.question1 {
  width: 100%;
}
.question-box h4 {
  font-family: 'Raleway', sans-serif;
  font-size: 22px;
  color: #1e1e1e;
  font-weight: 500;
}
.question-box ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.question-box ul li.active {
  color: red;
}
.answer-box {
  border: 1px solid #5e5e5e;
  padding: 5px 12px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  float: left;
  width: auto;
}
.answer-box-rgt {
  justify-content: space-between;
}
.answer-box-rgt ul {
  padding: 0;
  margin: 0;
  display: flex;
}
.answer-box-rgt ul li {
  cursor: pointer;
  list-style: none;
}
.answer-box-rgt ul li a {
  text-decoration: none;
  border: none;
  margin: 0 5px;
  background: #ffffff;
  white-space: nowrap;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.07);
  border-radius: 12px;
  color: black;
  padding: 12px 24px;
  transition: 100ms all ease-out;
}
.answer-box-rgt ul li.active a {
  background-color: #51777c;
  color: white;
}

.grid-selection {
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow: column;
}

.feature-selection {
  display: grid;
  grid-template-rows: repeat(7, 1fr);
  grid-auto-flow: column;
}

.form-2 {
  width: 47%;
  margin: 20px 0;
}

.slider-range {
  margin: 1rem 0 4rem;
  display: flex;
  justify-content: space-between;
}

.duration-range {
  margin-top: 70px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.duration-header {
  text-align: center;
  margin-bottom: 30px;
}

.slider-range h4 {
  margin-top: 40px;
}

.slider-parent {
  position: relative;
  width: 100%;
}

.text-area {
  width: 100%;
  height: 150px;
  padding: 22px;
  border: none;
  line-height: 130%;
  font-family: "Inter";
  font-size: 14px;
  border-radius: 12px;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.08);
  resize: none;
}

.text-area::-webkit-input-placeholder {
  color: #868686;
  font-family: "Inter";
  font-size: 14px;
}

.text-area:-ms-input-placeholder {
  color: #868686;
  font-family: "Inter";
  font-size: 14px;
}

.text-area::placeholder {
  color: #868686;
  font-family: "Inter";
  font-size: 14px;
}

.validation-error {
  color: red;
  padding: 10px;
  height: 20px;
  font-size: 26px;
  display: none;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.duration-slider {
  top: 0;
  position: absolute;
  margin-bottom: 30px;
}

.flag-holder {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flag-box {
  width: 20%;
  display: flex;
  justify-content: center;
}

.flag {
  width: 250px;
  margin-inline: 60px;
  height: 100px;
  cursor: pointer;
}

.highlight {
  border: 2px solid rgb(224, 3, 3);
}

.heading-container {
  text-align: center;
  margin-bottom: 0px;
}

.heading-container h4 {
  color: grey;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 3rem;
}

.tooltip-ex {
  /* Container for our tooltip */
  display: inline-block;
}

.tooltip-ex .tooltip-ex-text {
  /* This is for the tooltip text */
  visibility: hidden;
  width: 150px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #51777c;
  color: white;
  padding: 10px;
  font-size: 12px;
  line-height: 140%;
  border-radius: 10px; /* This defines tooltip text position */
  position: absolute;
  font-family: "Inter", sans-serif;
  z-index: 1;
  left: 95%;
  top: 5px;
  margin-left: 10px;
}

.tooltip-ex:hover .tooltip-ex-text {
  /* Makes tooltip text visible when text is hovered on */
  visibility: visible;
}

.more-info {
  margin-top: 2.5px;
  margin-right: 2px;
  cursor: help;
  /*position: absolute;*/
  right: 6px;
  bottom: 8px;
}

.bcheckimg {
  /*position: absolute;*/
  right: 14px;
  color: white;
}

/*Language*/
.lang_container {
  width: 40%;
  display: flex;
  justify-content: space-around;
}

.lang_holder {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  float: left;
}

.lang_box {
  width: 49%;
  float: left;
}

.language {
  padding: 3rem;
  background: white;
  box-shadow: 0px 7px 40px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  margin-bottom: 20px;
  cursor: pointer;
  float: left;
  margin: 0 20px 15px;
  text-align: center;
}

.langflag {
  width: 100%;
  height: 80px;
}

.validation-text {
  font-size: 16px;
  color: black;
  margin-top: -10px;
  margin-left: 2px;
}
.midheader {
  margin-top: 0px;
}

.selection-text {
  display: none;
}

.feature::selection .selection-text {
}

.sendmrgn1 {
  margin-left: 12.8%;
}
.sendmrgn2 {
  margin-left: 16%;
}
.sendmrgn3 {
  margin-left: 0.4%;
}

.mt {
  margin-top: 2rem;
}

.screen {
  width: 100%;
}

/* Footer */
.footer {
  position: fixed;
  left: 0;
  bottom: 2rem;
  color: black;
  width: 100%;
  text-align: center;
}

/*@charset "utf-8";*/
/*!* CSS Document *!*/

/*.button-width {*/
/*  width: 10%;*/
/*}*/

/*@media only screen and (max-width: 1920px) {*/
/*  .sendmrgn1 {*/
/*    margin-left: 5%;*/
/*  }*/
/*  .check-box {*/
/*    width: 97.6%;*/
/*  }*/
/*  .text-area {*/
/*    width: 97.6%;*/
/*  }*/
/*  .inpt1 {*/
/*    width: 97.6%;*/
/*  }*/
/*  .buttons-row {*/
/*    width: 100%;*/
/*  }*/
/*  .sendmrgn2 {*/
/*    margin-left: 5%;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 1920px) {*/
/*  .progress-bar {*/
/*    position: absolute;*/
/*    right: 42.6%;*/
/*    bottom: 20px;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 1380px) {*/
/*}*/

/*@media only screen and (max-width: 1366px) {*/
/*  .form-container-scnd {*/
/*    width: 76%;*/
/*    margin: 0 auto;*/
/*  }*/
/*  .button-width {*/
/*    width: 20%;*/
/*  }*/

/*  .progress-bar {*/
/*    position: absolute;*/
/*    right: 43%;*/
/*    bottom: 20px;*/
/*  }*/
/*  .sendmrgn1 {*/
/*    margin-left: 15%;*/
/*  }*/
/*  .buttons-row {*/
/*    width: 99%;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 1024px) {*/
/*  .container {*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*  }*/
/*  .form-container {*/
/*    width: 85%;*/
/*    margin: 0 auto;*/
/*  }*/
/*  .inpt1 {*/
/*    width: 97%;*/
/*  }*/
/*  .inpt3 {*/
/*    width: 97%;*/
/*  }*/
/*  .form-container-scnd {*/
/*    width: 90%;*/
/*  }*/
/*  .button-width {*/
/*    width: 15%;*/
/*  }*/
/*  .form-submit {*/
/*    width: 50%;*/
/*  }*/
/*  .language {*/
/*    padding: 25px 10px;*/
/*  }*/
/*  .lang_container {*/
/*    width: 70%;*/
/*  }*/
/*  .sendmrgn1 {*/
/*    margin-left: 9.9%;*/
/*  }*/
/*  .answers .buttons-row {*/
/*    width: 86%;*/
/*  }*/
/*  .buttons-row {*/
/*    width: 100%;*/
/*  }*/
/*  .sendmrgn2 {*/
/*    margin-left: 9%;*/
/*  }*/
/*  .sendmrgn3 {*/
/*    margin-left: 11%;*/
/*  }*/
/*  .progress-bar {*/
/*    position: absolute;*/
/*    right: 40%;*/
/*    bottom: 20px;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 768px) {*/
/*  .rgt-aline1 {*/
/*    width: 35%;*/
/*  }*/
/*  .slct-bx1 {*/
/*    width: 31%;*/
/*    margin-right: 17px;*/
/*  }*/
/*  .form-container-scnd,*/
/*  .form-2 {*/
/*    width: 97%;*/
/*  }*/
/*  .header {*/
/*    margin-top: 40px;*/
/*  }*/
/*  .check-box {*/
/*    width: 96%;*/
/*  }*/
/*  .text-area {*/
/*    width: 96%;*/
/*  }*/
/*  .question-box {*/
/*    width: 95%;*/
/*  }*/
/*  .more-info {*/
/*    font-size: 11px;*/
/*    height: 14px;*/
/*    width: 16px;*/
/*  }*/
/*  .App h4 {*/
/*    color: #fff;*/
/*  }*/

/*  .tooltip-ex .tooltip-ex-text {*/
/*    left: 164px;*/
/*    margin-left: 0px;*/
/*    top: 35px;*/
/*  }*/
/*  .sendmrgn1 {*/
/*    margin-left: 4%;*/
/*  }*/
/*  .sendmrgn2 {*/
/*    margin-left: 5%;*/
/*  }*/
/*  .progress-bar {*/
/*    position: absolute;*/
/*    right: 37%;*/
/*    bottom: 20px;*/
/*  }*/
/*  .answers .buttons-row {*/
/*    margin: 50px 9% 0;*/
/*  }*/

/*  !*Color Change*!*/
/*  body {*/
/*    background-color: #18191a;*/
/*  }*/
/*  a {*/
/*    color: white;*/
/*  }*/
/*  .footer {*/
/*    color: white;*/
/*  }*/
/*  .header {*/
/*    color: #fff;*/
/*  }*/
/*  .progress-title {*/
/*    color: #fff;*/
/*  }*/
/*  .card-box {*/
/*    color: #fff;*/
/*  }*/
/*  .card-box h4 {*/
/*    color: #fff;*/
/*  }*/
/*  .inner-container a {*/
/*    color: #fff;*/
/*  }*/
/*  .btn1 {*/
/*    background-color: #fff;*/
/*    color: #000 !important;*/
/*  }*/
/*  .btn1:hover {*/
/*    background-color: #ffff4f;*/
/*    color: #fff !important;*/
/*  }*/
/*  .inpt1,*/
/*  .inpt2,*/
/*  .inpt3 {*/
/*    color: #fff;*/
/*  }*/
/*  .heading-container h5 {*/
/*    color: #fff;*/
/*  }*/
/*  .validation-text {*/
/*    color: #fff;*/
/*  }*/
/*  .check-box {*/
/*    color: #fff;*/
/*  }*/
/*  .heading-container h4 {*/
/*    color: #fff;*/
/*  }*/
/*  .select-box h4 {*/
/*    color: #fff;*/
/*    font-size: 13px;*/
/*  }*/
/*  .tooltip-ex {*/
/*    color: #fff;*/
/*  }*/
/*  .more-info {*/
/*    border: 1px solid #fff;*/
/*    color: #fff;*/
/*  }*/
/*  .question-box h4 {*/
/*    color: #fff;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    color: #fff;*/
/*  }*/
/*  .form-submit h4 {*/
/*    color: #fff;*/
/*  }*/
/*  .duration-slider {*/
/*    background: #fff !important;*/
/*  }*/
/*  .App h3 {*/
/*    color: #fff;*/
/*  }*/
/*  #succesful h2 {*/
/*    color: #fff;*/
/*  }*/
/*  .buttons-row {*/
/*    color: #fff;*/
/*  }*/
/*  input[type="range"]::-ms-track {*/
/*    width: 100%;*/
/*    cursor: pointer;*/
/*    background: transparent;*/
/*    border-color: transparent;*/
/*    color: transparent;*/
/*  }*/

/*  input[type="range"]::-webkit-slider-thumb {*/
/*    -webkit-appearance: none;*/
/*    height: 36px;*/
/*    width: 36px;*/
/*    border-radius: 3px;*/
/*    background: white;*/
/*    cursor: pointer;*/
/*    margin-top: -16px;*/
/*  }*/

/*  !* All the same stuff for Firefox *!*/
/*  input[type="range"]::-moz-range-thumb {*/
/*    height: 36px;*/
/*    width: 36px;*/
/*    border-radius: 3px;*/
/*    background: white;*/
/*    cursor: pointer;*/
/*    margin-top: -16px;*/
/*  }*/

/*  !* All the same stuff for IE *!*/
/*  input[type="range"]::-ms-thumb {*/
/*    height: 36px;*/
/*    width: 36px;*/
/*    border-radius: 3px;*/
/*    background: white;*/
/*    cursor: pointer;*/
/*    margin-top: -16px;*/
/*  }*/

/*  input[type="range"]::-webkit-slider-runnable-track {*/
/*    width: 100%;*/
/*    height: 4px;*/
/*    cursor: pointer;*/
/*    background: white;*/
/*    border-radius: 5px;*/
/*    border: 0.2px solid white;*/
/*  }*/

/*  input[type="range"]:focus::-webkit-slider-runnable-track {*/
/*    background: white;*/
/*  }*/

/*  input[type="range"]::-moz-range-track {*/
/*    width: 100%;*/
/*    height: 4px;*/
/*    cursor: pointer;*/
/*    border-radius: 1.3px;*/
/*    border: 0.2px solid #010101;*/
/*  }*/

/*  input[type="range"]::-ms-track {*/
/*    width: 100%;*/
/*    height: 3px;*/
/*    cursor: pointer;*/
/*    background: transparent;*/
/*    border-color: transparent;*/
/*    border-width: 16px 0;*/
/*    color: transparent;*/
/*  }*/
/*  input[type="range"]::-ms-fill-lower {*/
/*    background: white;*/
/*    border: 0.2px solid white;*/
/*    border-radius: 2.6px;*/
/*    box-shadow: 1px 1px 1px gray, 0px 0px 1px gray;*/
/*  }*/
/*  input[type="range"]:focus::-ms-fill-lower {*/
/*    background: white;*/
/*  }*/
/*  input[type="range"]::-ms-fill-upper {*/
/*    background: white;*/
/*    border: 0.2px solid white;*/
/*    border-radius: 2.6px;*/
/*    box-shadow: 1px 1px 1px gray, 0px 0px 1px gray;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 830px) {*/
/*  .grid-selection {*/
/*    grid-template-rows: repeat(8, 1fr);*/
/*  }*/

/*  .feature-selection {*/
/*    grid-template-rows: repeat(10, 1fr);*/
/*  }*/

/*  .check-box {*/
/*    width: 96%;*/
/*  }*/

/*  .form-container-scnd,*/
/*  .form-2 {*/
/*    width: 80%;*/
/*    margin: 0 auto;*/
/*  }*/
/*  .button-width {*/
/*    width: 12%;*/
/*  }*/
/*  .form-submit {*/
/*    width: 50%;*/
/*  }*/

/*  .progress-title {*/
/*    margin-top: 30px;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 767px) {*/
/*  .container {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    padding-bottom: 80px;*/
/*    padding-right: 0;*/
/*    padding-left: 0px;*/
/*  }*/
/*  .form-container,*/
/*  .form-2 {*/
/*    width: 95%;*/
/*  }*/
/*  .card {*/
/*    width: auto;*/
/*    margin: 0 10px 15px;*/
/*  }*/
/*  .inpt1 {*/
/*    width: 96%;*/
/*  }*/
/*  .inpt3 {*/
/*    width: 96%;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    padding: 2px 5px;*/
/*    font-size: 16px;*/
/*  }*/
/*  .button-width {*/
/*    width: 10%;*/
/*  }*/
/*  .sendmrgn2 {*/
/*    margin-left: 1%;*/
/*  }*/

/*  .progress-bar {*/
/*    position: absolute;*/
/*    right: 37%;*/
/*    bottom: 20px;*/
/*  }*/

/*  .footer {*/
/*    position: fixed;*/
/*    left: 0;*/
/*    bottom: 0;*/
/*    width: 100%;*/
/*    text-align: center;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 667px) {*/
/*  .check-box {*/
/*    width: 75%;*/
/*  }*/
/*  .slct-bx1 {*/
/*    margin-right: 15px;*/
/*  }*/
/*  .question-box h4 {*/
/*    font-size: 16px;*/
/*  }*/
/*  .header {*/
/*    font-size: 20px;*/
/*    margin-inline: 20px;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    padding: 1px 5px;*/
/*    width: 100%;*/
/*    margin: 2px;*/
/*  }*/
/*  .question-box {*/
/*    flex-direction: column;*/
/*    line-height: 30px;*/
/*  }*/
/*  .question-box-lft {*/
/*    text-align: center;*/
/*  }*/
/*  .form-container-scnd,*/
/*  .form-2 {*/
/*    width: 90%;*/
/*  }*/
/*  .answer-box-rgt {*/
/*    margin-top: 15px;*/
/*    font-size: 16px;*/
/*    width: 50%;*/
/*  }*/
/*  .answer-box-rgt ul {*/
/*    justify-content: space-between;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    font-weight: 600;*/
/*    cursor: pointer;*/
/*  }*/
/*  .buttons-row {*/
/*    margin: auto;*/
/*    margin-top: 30px;*/
/*  }*/
/*  .inner-container .check-box {*/
/*    width: 95%;*/
/*  }*/
/*  .progress-bar {*/
/*    position: static;*/
/*    margin-bottom: 30px;*/
/*  }*/
/*  .progress-title {*/
/*    position: static;*/
/*    margin-top: 30px;*/
/*  }*/
/*  .text-area {*/
/*    width: 95%;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    width: 120px;*/
/*    float: left;*/
/*    text-align: center;*/
/*  }*/
/*  .answer-box-rgt {*/
/*    width: 100%;*/
/*  }*/
/*  .question-box {*/
/*    width: 80%;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 425px) {*/
/*  .lang_container {*/
/*    width: 100%;*/
/*  }*/
/*  .card-box h4 {*/
/*    margin-bottom: 30px;*/
/*  }*/
/*  .midheader {*/
/*    margin-top: 140px;*/
/*  }*/
/*  .lang_box {*/
/*    width: 100%;*/
/*    margin: 0 auto;*/
/*    display: flex;*/
/*  }*/
/*  .language {*/
/*    width: 25%;*/
/*    margin: 0 auto;*/
/*  }*/
/*  .inpt1 {*/
/*    width: 94%;*/
/*  }*/
/*  .inner-container .check-box {*/
/*    width: 94%;*/
/*  }*/
/*  .select-box {*/
/*    margin: 0 2px 15px;*/
/*  }*/
/*  progress[value] {*/
/*    width: 350px;*/
/*  }*/
/*  .text-area {*/
/*    width: 95%;*/
/*  }*/
/*  .answer-box-rgt {*/
/*    width: 100%;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    width: 85px;*/
/*    float: left;*/
/*    text-align: center;*/
/*  }*/
/*  .answers .buttons-row {*/
/*    margin: 0;*/
/*    margin-top: 30px;*/
/*  }*/
/*  .btn1 {*/
/*    padding: 10px 60px;*/
/*    margin: 0 auto 0 70px;*/
/*  }*/
/*  .form-submit {*/
/*    width: 90%;*/
/*  }*/
/*  #succesful {*/
/*    padding-top: 210px;*/
/*  }*/
/*  .progress-title {*/
/*    margin-top: 90px;*/
/*  }*/
/*  body {*/
/*    background-color: #18191a;*/
/*  }*/
/*  .header {*/
/*    color: #fff;*/
/*  }*/
/*  .progress-title {*/
/*    color: #fff;*/
/*  }*/
/*  .card-box {*/
/*    color: #fff;*/
/*  }*/
/*  .card-box h4 {*/
/*    color: #fff;*/
/*  }*/
/*  .inner-container a {*/
/*    color: #fff;*/
/*  }*/
/*  .btn1 {*/
/*    background-color: #fff;*/
/*    color: #000 !important;*/
/*  }*/
/*  .btn1:hover {*/
/*    background-color: #ffff4f;*/
/*    color: #fff !important;*/
/*  }*/
/*  .inpt1,*/
/*  .inpt2,*/
/*  .inpt3 {*/
/*    color: #fff;*/
/*  }*/
/*  .heading-container h5 {*/
/*    color: #fff;*/
/*  }*/
/*  .validation-text {*/
/*    color: #fff;*/
/*  }*/
/*  .check-box {*/
/*    color: #fff;*/
/*  }*/
/*  .heading-container h4 {*/
/*    color: #fff;*/
/*  }*/
/*  .select-box h4 {*/
/*    color: #fff;*/
/*    font-size: 13px;*/
/*  }*/
/*  .tooltip-ex {*/
/*    color: #fff;*/
/*  }*/
/*  .more-info {*/
/*    border: 1px solid #fff;*/
/*  }*/
/*  .question-box h4 {*/
/*    color: #fff;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    color: #fff;*/
/*  }*/
/*  .form-submit h4 {*/
/*    color: #fff;*/
/*  }*/
/*  .duration-slider {*/
/*    background: #fff !important;*/
/*  }*/
/*  .App h3 {*/
/*    color: #fff;*/
/*  }*/
/*  #succesful h2 {*/
/*    color: #fff;*/
/*  }*/
/*  .buttons-row {*/
/*    color: #fff;*/
/*  }*/
/*  progress[value] {*/
/*    width: 100%;*/
/*  }*/
/*  .btn1 {*/
/*    margin: 0 auto 0 72px !important;*/
/*    padding: 10px 40px;*/
/*  }*/
/*  .App h4 {*/
/*    color: #fff;*/
/*  }*/

/*  input[type="range"]::-webkit-slider-thumb {*/
/*    -webkit-appearance: none;*/
/*    height: 36px;*/
/*    width: 36px;*/
/*    border-radius: 3px;*/
/*    background: white;*/
/*    cursor: pointer;*/
/*    margin-top: -16px;*/
/*  }*/

/*  !* All the same stuff for Firefox *!*/
/*  input[type="range"]::-moz-range-thumb {*/
/*    height: 36px;*/
/*    width: 36px;*/
/*    border-radius: 3px;*/
/*    background: white;*/
/*    cursor: pointer;*/
/*    margin-top: -16px;*/
/*  }*/

/*  !* All the same stuff for IE *!*/
/*  input[type="range"]::-ms-thumb {*/
/*    height: 36px;*/
/*    width: 36px;*/
/*    border-radius: 3px;*/
/*    background: white;*/
/*    cursor: pointer;*/
/*    margin-top: -16px;*/
/*  }*/

/*  input[type="range"]::-webkit-slider-runnable-track {*/
/*    width: 100%;*/
/*    height: 4px;*/
/*    cursor: pointer;*/
/*    background: white;*/
/*    border-radius: 5px;*/
/*    border: 0.2px solid white;*/
/*  }*/

/*  input[type="range"]:focus::-webkit-slider-runnable-track {*/
/*    background: white;*/
/*  }*/

/*  input[type="range"]::-moz-range-track {*/
/*    width: 100%;*/
/*    height: 4px;*/
/*    cursor: pointer;*/
/*    border-radius: 1.3px;*/
/*    border: 0.2px solid #ffffff;*/
/*  }*/

/*  input[type="range"]::-ms-track {*/
/*    width: 100%;*/
/*    height: 3px;*/
/*    cursor: pointer;*/
/*    background: transparent;*/
/*    border-color: transparent;*/
/*    border-width: 16px 0;*/
/*    color: transparent;*/
/*  }*/
/*  input[type="range"]::-ms-fill-lower {*/
/*    background: white;*/
/*    border: 0.2px solid white;*/
/*    border-radius: 2.6px;*/
/*    box-shadow: 1px 1px 1px gray, 0px 0px 1px gray;*/
/*  }*/
/*  input[type="range"]:focus::-ms-fill-lower {*/
/*    background: white;*/
/*  }*/
/*  input[type="range"]::-ms-fill-upper {*/
/*    background: white;*/
/*    border: 0.2px solid white;*/
/*    border-radius: 2.6px;*/
/*    box-shadow: 1px 1px 1px gray, 0px 0px 1px gray;*/
/*  }*/

/*  .sendmrgn2 {*/
/*    margin-left: 22% !important;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 414px) {*/
/*  .container {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    padding-bottom: 80px;*/
/*  }*/
/*  .form-container {*/
/*    width: 93%;*/
/*  }*/
/*  .card {*/
/*    width: 70%;*/
/*    margin: 0 10px 15px;*/
/*  }*/
/*  .rgt-aline1 {*/
/*    width: 75%;*/
/*  }*/
/*  .inpt1 {*/
/*    width: 94%;*/
/*  }*/
/*  .inpt3 {*/
/*    width: 94%;*/
/*  }*/
/*  .slct-bx1 {*/
/*    width: 100%;*/
/*    margin-right: 0;*/
/*  }*/
/*  .check-box {*/
/*    width: 93%;*/
/*  }*/
/*  .tool::before,*/
/*  .tool::after {*/
/*    left: 0%;*/
/*  }*/
/*  .tool::after {*/
/*    font-size: 0.75em;*/
/*    margin-left: -35em;*/
/*    width: 30em;*/
/*  }*/
/*  .header {*/
/*    font-size: 22px;*/
/*    padding: 10px 10px;*/
/*  }*/
/*  .question-box {*/
/*    width: 95%;*/
/*  }*/
/*  .question-box-lft {*/
/*    width: 60%;*/
/*  }*/
/*  .answer-box-rgt ul {*/
/*    padding: 5px;*/
/*    align-items: center;*/
/*    flex-direction: column;*/
/*  }*/
/*  .answer-box-rgt ul li {*/
/*    margin: 5px 0;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    padding: 5px 10px;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    font-size: 13px;*/
/*  }*/
/*  .card-box {*/
/*    width: 100%;*/
/*  }*/
/*  .grid-selection {*/
/*    grid-template-rows: repeat(16, 1fr);*/
/*  }*/
/*  .feature-selection {*/
/*    grid-template-rows: repeat(20, 1fr);*/
/*  }*/
/*  .select-box {*/
/*    width: 93%;*/
/*  }*/
/*  .btn1 {*/
/*    margin: 0 auto 0 45px;*/
/*  }*/
/*  .btn1 {*/
/*    margin: 0 auto 0 70px !important;*/
/*    padding: 10px 40px;*/
/*  }*/
/*  .more-info {*/
/*    bottom: 5px;*/
/*    color: #fff;*/
/*  }*/
/*  .bcheckimg {*/
/*    bottom: 4px;*/
/*  }*/
/*  .answer-box-rgt ul {*/
/*    width: auto !important;*/
/*  }*/
/*  .answers .buttons-row {*/
/*    width: 100%;*/
/*  }*/
/*  progress[value] {*/
/*    width: 100%;*/
/*  }*/
/*  .form-submit {*/
/*    width: 90% !important;*/
/*  }*/
/*  .App h4 {*/
/*    color: #fff;*/
/*  }*/
/*  .sendmrgn2 {*/
/*    margin-left: 22% !important;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 662px) {*/
/*  .question-box {*/
/*    width: 95%;*/
/*  }*/
/*  .question-box-lft {*/
/*    width: 60%;*/
/*  }*/
/*  .answer-box-rgt ul {*/
/*    padding: 5px;*/
/*  }*/
/*  .answer-box-rgt ul li {*/
/*    margin: 5px 0;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    padding: 5px 10px;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    font-size: 13px;*/
/*  }*/
/*  .form-submit {*/
/*    width: 80%;*/
/*  }*/
/*  .text-area {*/
/*    width: 94%;*/
/*  }*/
/*}*/
/*@media only screen and (max-width: 384px) {*/
/*  .answer-box-rgt ul li {*/
/*    margin: 0;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    padding: 5px 10px;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    font-size: 13px;*/
/*  }*/
/*  progress[value] {*/
/*    width: 100%;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 260px) {*/
/*  .answer-box-rgt ul li {*/
/*    margin: 0;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    padding: 2px;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    font-size: 13px;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 375px) {*/
/*  .card-box {*/
/*    width: 100%;*/
/*    float: left;*/
/*  }*/
/*  .form-container {*/
/*    width: 94%;*/
/*  }*/
/*  .card-box h4 {*/
/*    margin-bottom: 10px;*/
/*  }*/
/*  progress[value] {*/
/*    width: 320px;*/
/*  }*/
/*  .btn1 {*/
/*    margin: 0 auto 0 32px;*/
/*  }*/
/*  .btn1 {*/
/*    margin: 0 auto 0 45px !important;*/
/*    padding: 10px 40px;*/
/*  }*/
/*  progress[value] {*/
/*    width: 100%;*/
/*  }*/
/*  .sendmrgn2 {*/
/*    margin-left: 20% !important;*/
/*  }*/
/*  .sendmrgn1 {*/
/*    margin-left: 15% !important;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 470px) {*/
/*  .answer-box-rgt ul {*/
/*    flex-direction: column;*/
/*    width: 100%;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*  }*/
/*  .answer-box-rgt ul li a {*/
/*    padding: 5px 30px;*/
/*  }*/
/*  .flag-holder {*/
/*    margin-top: 15px;*/
/*  }*/

/*  .flag-box {*/
/*    width: 30%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*  }*/

/*  .flag {*/
/*    width: 250px;*/
/*    margin-inline: 60px;*/
/*    height: 100px;*/
/*    border-radius: 20px;*/
/*    cursor: pointer;*/
/*  }*/
/*  progress[value] {*/
/*    width: 100%;*/
/*  }*/
/*}*/
/*@media only screen and (max-width: 360px) {*/
/*  .card-box {*/
/*    width: 100%;*/
/*    float: left;*/
/*  }*/
/*  .form-submit {*/
/*    width: 70%;*/
/*  }*/
/*  progress[value] {*/
/*    width: 320px;*/
/*  }*/
/*  progress[value] {*/
/*    width: 100%;*/
/*  }*/
/*}*/

/*@media only screen and (max-width: 320px) {*/
/*  .container {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*  }*/
/*  .form-container {*/
/*    width: 95%;*/
/*  }*/
/*  .card-box {*/
/*    width: 100%;*/
/*    float: left;*/
/*  }*/
/*  .card {*/
/*    width: 70%;*/
/*    margin: 0 auto 15px;*/
/*  }*/
/*  .inpt1 {*/
/*    width: 92%;*/
/*  }*/
/*  .inpt3 {*/
/*    width: 92%;*/
/*  }*/
/*  .inner-container .check-box {*/
/*    width: 92%;*/
/*  }*/
/*  .select-box {*/
/*    width: 92%;*/
/*  }*/
/*  .header {*/
/*    font-size: 18px;*/
/*    padding: 10px 10px;*/
/*  }*/
/*  .language {*/
/*    padding: 15px 10px;*/
/*  }*/
/*  progress[value] {*/
/*    width: 100%;*/
/*  }*/
/*  .text-area {*/
/*    width: 92%;*/
/*  }*/
/*  .btn1 {*/
/*    margin: 0 auto 0 20px;*/
/*  }*/
/*  .btn1 {*/
/*    margin: 0 auto 0 28px !important;*/
/*    padding: 10px 40px;*/
/*  }*/
/*  .sendmrgn2 {*/
/*    margin-left: 14% !important;*/
/*  }*/

/*  .sendmrgn1 {*/
/*    margin-left: 10% !important;*/
/*  }*/
/*}*/

/*!* Safari Browsers *!*/
/*@supports (-webkit-touch-callout: none) {*/
/*}*/

.inner-container form {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.inner-container form input {
	width: 100%;
}

@media only screen and (max-width: 450px) {
  .lang_container {
    width: 90%;
  }

  .lang_holder {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .lang_box {
    width: 90%;
  }

  .form-container {
    width: 90%;
  }

  .card-holder {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .card-box {
    width: 80%;
  }

  .buttons-row {
    width: 100%;
    justify-content: space-between;
  }

  .inner-container form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .inner-container form input {
    width: 100%;
  }

  .header {
    width: 90%;
    margin: 0 auto 1rem;
    padding: 0 0.5rem;
  }

  .progress-title {
    right: -10px;
    bottom: 45px;
  }

  .answers {
    width: 90% !important;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    flex-direction: column;
  }

	.answers .buttons-row {
		margin: 2rem 0;
	}

  .question-box {
    flex-direction: column;
    width: 100%;
    margin: 0;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .question-box-lft {
    width: 90%;
    text-align: center;
  }

  .form-container-scnd {
    width: 90%;
  }

  .feature-selection {
    display: flex;
    flex-direction: column;
  }

  .select-box {
    width: 100%;
    padding: 16px;
  }

  .container-mobile {
    height: unset;
  }

  .features-heading {
    margin-top: 55rem;
  }

	.form-submit {
		width: 80%;
	}

	.sendmrgn2 {
		margin: 0;
	}
}

